export const menuItems = [
    {
        id: 1,
        label: "menu.menuitems.branches.text",
        icon: "bx bxs-home",
        link: '/branches'
    },
    {
        id: 2,
        label: "menu.menuitems.countries.text",
        icon: "bx bxs-flag",
        link: '/countries'
    },
    {
        id: 3,
        label: "menu.menuitems.customers.text",
        icon: "bx bxs-user",
        link: '/customers'
    },
    {
        id: 5,
        label: "menu.menuitems.orders.text",
        icon: "bx bxs-cart",
        link: '/orders'
    },
    {
        id: 6,
        label: "menu.menuitems.users.text",
        icon: "bx bxs-group",
        link: '/users'
    },
    {
        id: 7,
        label: "menu.menuitems.workers.text",
        icon: "bx bxs-briefcase",
        link: '/workers'
    },

    {
        id: 7,
        label: "menu.menuitems.visa_tickets.text",
        icon: "bx bxs-briefcase",
        link: '/visa-tickets'
    },
    {
        id: 7,
        label: "menu.menuitems.insurance.text",
        icon: "bx bxs-briefcase",
        link: '/insurance'
    },
    {
        id: 7,
        label: "menu.menuitems.returned.text",
        icon: "bx bxs-briefcase",
        link: '/returned'
    },

];
